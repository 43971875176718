<template>
  <div class="sidebar">
    <div class="menuHeader">
      <span class="menuHeaderText">리더십 진단평가 관리자</span>
    </div>
    <ul class="menu">
      <ul class="sub-menu">
        <!-- <li v-if="clicked1" @click="move('Assignment'), changeMenu('Assignment')" class="menuHeaderText mt-1" :class="{ active: curPath === 'Assignment' }"><b>배정 그룹 등록​</b></li>
        <li v-else @click="move('Assignment'), changeMenu('Assignment')" class="menuHeaderText mt-1" :class="{ active: curPath === 'Assignment' }">배정 그룹 등록​</li>
        <li v-if="clicked2" @click="move('ListSituation'), changeMenu('ListSituation')" class="menuHeaderText mt-1" :class="{ active: curPath === 'ListSituation' }">
          <b>리스트 및 현황</b>
        </li>
        <li v-else @click="move('ListSituation'), changeMenu('ListSituation')" class="menuHeaderText mt-1" :class="{ active: curPath === 'ListSituation' }">리스트 및 현황</li> -->
        <li :class="{ menuHeaderText: curPath === 'GenerateDiagnosisCreate' && route.query.type === 'create' }" @click="move('GenerateDiagnosisCreate', 'create')" class="mt-1">
          리더십 진단 생성
        </li>
        <li
          :class="{
            menuHeaderText: curPath === 'DiagnosisManagementList' || curPath === 'DiagnosisManagementDetail' || (curPath === 'GenerateDiagnosisCreate' && route.query.type === 'edit'),
          }"
          @click="move('DiagnosisManagementList')"
          class="mt-1">
          리더십 진단 관리
        </li>
        <li :class="{ menuHeaderText: curPath === 'QuestionManagementList' || curPath === 'QuestionManagementCreate' }" @click="move('QuestionManagementList')" class="mt-1">
          리더십 문항 관리
        </li>
        <li @click="moveChanelTalk()">1:1 문의사항 관리</li>
      </ul>
    </ul>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const curPath = ref(null);
    const clicked1 = ref(false);
    const clicked2 = ref(false);

    function changeMenu(page) {
      clicked1.value = page == 'Assignment' ? true : false;
      clicked2.value = page == 'ListSituation' ? true : false;
    }

    const move = (page, type) => {
      router.push({ name: page, query: { type: type } });
    };

    const moveChanelTalk = () => {
      // 채널톡으로 변경 필요
      window.open('https://desk.channel.io/#/channels/166402/user_chats', '_Blank');
    };

    onMounted(() => {
      const { name } = route;

      curPath.value = name;
    });

    watch(route, cur => {
      const { name } = cur;
      curPath.value = name;
    });

    return {
      clicked1,
      clicked2,
      move,
      route,
      curPath,
      changeMenu,
      moveChanelTalk,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.sidebar {
  background-color: $side-bg-color;
  height: 100%;
  padding-right: 0;
  color: $side-text-color;

  a {
    display: block;
    color: $black;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
  }

  a.active {
    background-color: $side-text-active;
    color: $white;
  }

  .menu,
  .sub-menu {
    list-style: none;
    padding: 16px;
    margin-bottom: 0;

    .menu-nm {
      cursor: pointer;
    }

    .menu-nm.active {
      color: $side-text-active;
    }

    .menu-nm:hover {
      color: $side-text-active;
    }
  }

  .sub-menu {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 0;
    padding-bottom: 10px;

    li {
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .menuHeader {
    background-color: #088dff;
    padding: 34px 10px 34px 10px;
  }
  .menuHeaderText {
    color: #ffffff;
  }
}
</style>
